export const LATEST_FEED_ITEM_WRAPPER_WALL_FRAGMENT = `
  fragment latestFeedItemWrapperWallFragment on CommunityUser {
    _realTimeGlobalFeed (
      skip: 0
      limit: 1
    ) {
      uid
    }
  }
`;
